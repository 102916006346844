<template>
  <div>
    <div class="demo-spin-article">
      <div class="row">
        <div class="col-md-12">
          <variable-list
            v-model="model.template_detail"
            @sort="setNewSort"
            :validation="errors"
          />
          <div class="text-danger" v-if="errors.has('template_detail')">
            {{ errors.first("template_detail") }}
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
          <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
            {{ $t("cancel") }}</ts-button
          >
          <ts-button
            color="primary"
            v-if="!isUpdate"
            :waiting="waiting"
            :disabled="waiting_new || waiting"
            @click.prevent="onSave"
          >
            {{ $t("save") }}</ts-button
          >
          <ts-button
            v-if="isUpdate"
            color="primary"
            @click.prevent="onUpdate"
            :waiting="waiting"
          >
            {{ $t("update") }}</ts-button
          >
        </div>
      </div>
    </div>
    <Spin fix v-if="loading"></Spin>
  </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import { isEmpty } from "lodash";
import VariableList from "./varible-list.vue";

export default {
  name: "payroll-cycle-form",
  components: {
    VariableList,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
      waiting: false,
      waiting_new: false,
      model: {
        template_detail: [],
      },
    };
  },
  computed: {
    ...mapState("payroll/payslipTemplate", ["edit_data"]),
    isUpdate() {
      return !isEmpty(this.edit_data);
    },
  },
  methods: {
    ...mapActions("payroll/payslipTemplate", ["getFormViewData"]),
    setNewSort(value) {
      if (value.length > 0) {
        this.model.template_detail = value;
      }
    },
    async fetchFormViewModel({ params, index }) {
      this.loading = true;
      await this.getFormViewData({
        index: index,
        params: params,
      });
      this.loading = false;
    },
    async onSave() {
      this.errors = new Errors();
      this.waiting = true;
      this.$store
        .dispatch("payroll/payslipTemplate/store", this.model)
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("cancel");
          this.$emit("fetchData");
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async onSaveAddNew() {
      this.errors = new Errors();
      this.waiting_new = true;
      this.$store
        .dispatch("payroll/payslipTemplate/store", this.model)
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("fetchData");
          this.clearInput();
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting_new = false;
        });
    },
    onUpdate() {
      this.waiting = true;
      this.$store
        .dispatch("payroll/payslipTemplate/update", {
          id: this.edit_data.template_id,
          data: this.model,
        })
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("cancel");
          this.$emit("fetchData");
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async setEditData() {
      if (!isEmpty(this.edit_data)) {
        this.model.template_id = this.edit_data.template_id;
        this.model.template_detail = this.edit_data.details;
      }
    },
    clearInput() {
      this.errors = new Errors();
      this.model.template_detail = [];
    },
    notice(not) {
      this.$Notice[not.type]({
        title: "PAYSLIP TEMPLATE",
        desc: not.text,
      });
    },
  },
};
</script>
